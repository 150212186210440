<template>
  <div
    class="card mb-4"
    v-if="
      this.$store.state.getMovie.status.similarMovieLoading &&
      similarMovie.length
    "
  >
    <div class="card-header bg-success">
      <h4 class="text-center text-white my-2">
        فیلم های مشابه {{ movie.title }}
      </h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div
          class="col-sm-6 col-md-4 col-lg-3"
          v-for="movie in similarMovie"
          :key="movie.id"
        >
          <cardViewMovie
            :movie="movie"
            :size="imageSizeMovie('w342')"
          ></cardViewMovie>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cardViewMovie from "@/components/cardView/cardViewMovie";
export default {
  name: "SimilarMovie",
  components: { cardViewMovie },
  props: {
    movie: {
      type: Object,
      required: true,
    },
    similarMovie: {
      type: Object,
    },
  },
};
</script>

<style scoped></style>
