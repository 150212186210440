<template>
  <div class="card-view my-2">
    <div class="card-header">
      <router-link :to="{ name: 'SingleMovie', params: { id: movie.id } }">
        <img
          v-if="movie.poster_path"
          class="img-fluid w-100"
          :src="size + movie.poster_path"
          :title="movie.original_title"
          :alt="movie.original_title"
        />
        <img
          v-else
          class="img-fluid w-100"
          src="../../assets/no_image.jpg"
          :title="movie.original_title"
          :alt="movie.original_title"
        />
      </router-link>
    </div>
    <div class="card-movie-content">
      <div class="card-movie-content-head">
        <router-link
          :to="{ name: 'SingleMovie', params: { id: movie.id } }"
          class="d-block text-center"
          v-text="movie.title"
        ></router-link>
        <div class="ratings"><span v-text="movie.vote_average"></span>/10</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cardViewMovie",
  props: {
    movie: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
